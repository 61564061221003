<template>
  <div class="items view">
    <app-header :title="__('view.ingame.items.title')"/>
    <progress-linear v-if="loading" :indeterminate="true"/>

    <div class="control-bar" :class="{'control-bar--disabled': loading}">
      <div class="control-bar__info">
        <div class="control-bar__info-title">{{__('common.total')}}:</div>
        <div class="control-bar__info-value">{{ pagination.total }}</div>
      </div>
      <text-field
          class="control-bar__search"
          v-model="searchTerm"
          :label="__('common.search_term')"
          max-length="50"
          :hide-details="true"
          :clearable="true"
          @keyup:enter="refreshDelayed(100)"
          @click:clear="refreshDelayed(100)"
      />
      <div class="control-bar__buttons">
        <div class="control-bar__button" :title="__('common.refresh')" @click="refresh" v-slashes>
          <img class="control-bar__button-icon" src="https://cdn.hyperion-corporation.de/ui/svgs/refresh.svg"
               alt="refresh"/>
        </div>
        <div class="control-bar__button" :title="__('common.add')" @click="addItem" v-slashes>
          <img class="control-bar__button-icon" src="https://cdn.hyperion-corporation.de/ui/svgs/add.svg"
               alt="add"/>
        </div>
      </div>
    </div>
    <div class="scroll-horizontal-overflow">
      <pagination
          class="mt"
          :count-pages="pagination.pages"
          :total-visible="10"
          :page="pagination.page"
          :disabled="loading"
          @paginate="paginate"
      />
      <data-table
          :records="items"
          :fields="dataTableFields"
          :record-actions="recordActions"
          :sort-by="sortBy"
          :sort-desc="sortDesc"
          :loading="loading"
          @editItem="editItem"
          @sort="sort"
          @deleteRecord="deleteRecord"
      />
      <pagination
          class="mt"
          :count-pages="pagination.pages"
          :total-visible="10"
          :page="pagination.page"
          :disabled="loading"
          @paginate="paginate"
      />
    </div>
    <edit-item ref="editItem" @updated="refresh" @created="editItem"/>
  </div>
</template>

<script>
  import Swal from 'sweetalert2';
  import apiSecured from '../../api/secured';
  import DataTable from '../../components/DataTable';
  import AppHeader from '../../components/AppHeader';
  import EditItem from '../../components/Ingame/EditItem';
  import { notificationsMixin } from '../../mixins/notifications';
  import { localizationMixin } from '../../mixins/localization';
  import { paginationMixin } from "../../mixins/pagination";
  import { pageTitleMixin } from "../../mixins/pageTitle";

  export default {
    mixins: [notificationsMixin, localizationMixin, paginationMixin, pageTitleMixin],
    components: {
      appHeader: AppHeader,
      dataTable: DataTable,
      editItem: EditItem
    },
    data: () => ({
      loading: false,
      items: [],
      dataTableFields: [],
      recordActions: [],
    }),
    methods: {
      refreshDelayed(delay) {
        setTimeout(this.refresh, delay);
      },
      refresh() {
        this.loading = true;
        let params = this.getPaginationParams();
        params.showHidden = true;
        this.$router.push({ name: this.$route.name, query: params }).catch(err => {
        });
        apiSecured.get('/ingame/items/item', { params }).then(async (res) => {
          this.items = res.data;
          await this.enrichRecords();
          this.updatePagination(res);
          this.loading = false;
          this.showInfoNotification(this.__('common.items_loaded'));
        }).catch((error) => {
          this.loading = false;
          this.showErrorNotifications(error)
        });
      },
      async enrichRecords() {
        const groupIds = this.items.filter(record => record.groupId !== null).map(record => record.groupId);
        if (groupIds.length > 0) {
          await apiSecured.get(`/ingame/items/group/(${groupIds.join(',')})`, {
            params: {
              fields: 'id,name'
            },
          }).then((res) => {
            this.items = this.items.map(record => {
              record.group = res.data.find(resRecord => resRecord.id === record.groupId);
              return record;
            });
            this.showInfoNotification('Groups have been reloaded');
          }).catch((error) => {
            this.showErrorNotifications(error)
          });
        }
      },
      addItem() {
        this.$refs.editItem.showCreate();
      },
      editItem(item) {
        this.$refs.editItem.showEdit(item);
      },
      deleteRecord(record) {
        Swal.fire({
          title: this.__('common.are_you_sure'),
          text: this.__('common.do_you_want_to_delete'),
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: this.__('common.yes_delete_it')
        }).then((result) => {
          if (result.value) {
            this.loading = true;
            let endpoint = '/ingame/items/item/' + record.id;
            apiSecured.delete(endpoint).then((res) => {
              this.loading = false;
              this.showSuccessNotification(this.__('common.deleted'));
              this.refresh();
            }).catch((error) => {
              this.loading = false;
              this.showErrorNotifications(error);
              this.refresh();
            });
          }
        });
      },
    },
    created() {
      this.setPageTitle(this.__('entity.common.items'));
      this.dataTableFields = [
        { name: "icon", title: this.__('entity.common.icon'), type: "image" },
        { name: "id", title: this.__('entity.common.id'), type: "default" },
        { name: "key", title: 'Key', type: "default" },
        { name: "fullName", title: this.__('entity.common.title'), type: "default" },
        { name: "group.name", title: this.__('entity.common.group'), type: "ref", disableSorting: true },
        { name: "tier", title: this.__('entity.common.tier'), type: "numeric" },
        { name: "needReview", title: this.__('entity.common.need_review'), type: "boolean-inverted" },
        { name: "hidden", title: this.__('entity.common.hidden'), type: "boolean-inverted" },
        { name: "createdAt", title: this.__('entity.common.created_at'), type: "datetime" },
        { name: "updatedAt", title: this.__('entity.common.updated_at'), type: "datetime" }
      ];
      this.recordActions = [
        { title: this.__('component.ingame.edit_item.title'), icon: 'pencil', event: 'editItem' },
        {
          title: this.__('view.ingame.items.delete_item'),
          icon: 'bin',
          event: 'deleteRecord',
          restrictRoles: []
        }
      ];
      this.refresh();
    }
  }
</script>
